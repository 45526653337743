import { Progress } from "antd";
import { TypeMetric } from "../../../entities/metrics/type";

export default function Parasites({ parasites }: { parasites?: TypeMetric }) {
  return (
    <>
      <div className="flex flex-col px-10 py-5 gap-8 rounded-2xl border border-gray-200 w-full min-w-[300px] min-h-[300px]">
        <div className="flex flex-col gap-5">
          <h3 className="text-[19px] text-center">Слова паразиты</h3>
          <div className="flex flex-col gap-3">
            {parasites?.wordList?.map((word, index) => (
              <div
                key={`${word.key}_${index}`}
                className="flex flex-row items-center gap-5 w-full"
              >
                <p className="lg:w-1/2 text-xl ">{word.key}</p>
                <Progress
                  className="lg:flex-row items-center text-lg gap-4"
                  format={(percent?: number) =>
                    percent && `${Math.round(percent)}%`
                  }
                  percent={(word?.value / parasites?.value) * 100}
                  success={{
                    strokeColor: "#007BFC",
                  }}
                  size={[`100%`, 14]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
