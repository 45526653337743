import React from "react";
import MetricsHeader from "../../../features/MetricsHeader";
import Parasites from "./Parasites";
import { IMetricGroup } from "../../../entities/metrics/type";

interface WordsUsedProps {
  parasites?: IMetricGroup | undefined;
}

export default function WordsUsed({ parasites }: WordsUsedProps) {
  const parasitesIncluded =
    parasites &&
    parasites.value[0] &&
    parasites.value[0].wordList &&
    Array.isArray(parasites.value[0].wordList) &&
    parasites?.value[0].wordList.length;

  return (
    <div className="flex flex-col gap-8 w-full">
      <MetricsHeader title="Используемые слова" />

      {parasitesIncluded ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-1 gap-5">
          <Parasites parasites={parasites.value[0]} />
        </div>
      ) : (
        <p className="block py-[20px] text-center w-full text-[20px]">
          Нет данных
        </p>
      )}
    </div>
  );
}
