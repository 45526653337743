export const maxMetricsValues = {
  pitch: 400,
  loudness: 100,
  wpm: 180,
  // nauseaAcademic: 100,
  // parasitesCount: 100,
  // water: 100,
  // complexity: 25,
  // wordsInSentense: 45,
  // nativeWordsCount: 100,
  // rareWordsCount: 100,
  // connectionTypes: 10,
  // connectionCount: 25,
  // dangerous: 100,
  // inappropriate: 100,
  // obscenity: 100,
  // insult: 100,
  // threat: 100,
  // emotionality: 100,
  // joy: 100,
  // anger: 100,
  // mooCount: 50,
  // mooDuration: 25,
};
