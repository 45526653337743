import React from "react";
import { Outlet } from "react-router-dom";

const Layout: React.FC = () => {
  return (
    <>
      <main className="flex flex-col flex-1 w-full items-center mt-[15dvh] gap-8">
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
