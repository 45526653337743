import React from "react";
import MetricsHeader from "../../../features/MetricsHeader";

type Neighbour = {
  audio: string;
  img: string;
  name: string;
  score: number;
};

type NeigboursGroupProps = {
  group: Neighbour[];
};

export const NeighboursGroup: React.FC<NeigboursGroupProps> = ({ group }) => {
  return (
    <section className="flex flex-col w-full gap-8">
      <MetricsHeader title="Похожие голоса" />
      <div className="gap-4">
        {group && Array.isArray(group) && group.length !== 0 ? (
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-2 gap-5">
            {group.map((item, index) => {
              // подменяем http на https

              const imgAddress = item.img.replace(
                "http://193.124.33.40:5100",
                "https://api.charisma.datasea.me:5300"
              );

              return (
                <li
                  className="flex flex-row w-full gap-4 items-start p-7 rounded-2xl border border-gray-200"
                  key={index}
                >
                  <img
                    className="flex rounded-full max-w-[130px]"
                    src={imgAddress}
                  />
                  <div className="flex flex-col pt-5 gap-1">
                    <p className="font-semibold font-sans text-xl">
                      {item.name}
                    </p>
                    <p className="flex font-light text-lg w-full justify-start">
                      Совпадение {Math.round(item.score)}%
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="block py-[20px] text-center w-full text-[20px]">
            Нет данных
          </p>
        )}
      </div>
    </section>
  );
};

export default NeighboursGroup;
