import { Progress } from "antd";
import { cn } from "../../../lib/utils";
import { TypeMetric } from "../../entities/metrics/type";

interface CountBarsMetricsProps {
  item: TypeMetric;
}

export default function CountBarsMetrics({ item }: CountBarsMetricsProps) {
  if (!item) return null;

  const isGenderItem = item.name === "Вероятность пола";
  const maxBarValue = item.max || 100;
  const itemTitle = item.value
    ? `${item.percent}% Мужчина`
    : `${item.percent}% Женщина`;
  const progressPercent = maxBarValue ? (item.value / maxBarValue) * 100 : 0;
  const progressStatus =
    item.value < maxBarValue / 3
      ? "exception"
      : item.value > maxBarValue
      ? "success"
      : "normal";

  const colorText =
    item.value < maxBarValue / 3
      ? "#ff4d4f"
      : item.value > maxBarValue
      ? "#52c41a"
      : "#1677ff";

  return (
    <div
      className={cn(
        "flex flex-row items-baseline",
        !isGenderItem && "progress-wrap"
      )}
    >
      <p className={cn("flex text-2xl", !isGenderItem ? "lg:w-[30%]" : "mr-2")}>
        {item.name}:
      </p>
      {isGenderItem ? (
        <p className="flex items-center text-xl text-sm-b w-[10%] flex-row whitespace-nowrap">
          {itemTitle}
        </p>
      ) : (
        <div className="flex w-full items-baseline">
          <Progress
            className="lg:flex-row items-center text-2xl gap-4 count-progress-text"
            percent={progressPercent}
            success={{ strokeColor: "#007BFC" }}
            size={["100%", 15]}
            status={progressStatus}
          />
          <p
            className="flex items-center text-xl text-sm-b w-[10%]"
            style={{ color: colorText }}
          >
            {Math.round(item.value)}
          </p>
        </div>
      )}
    </div>
  );
}
