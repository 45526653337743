import { FC } from "react";

// import smlt_arrow from "../../public/SMLT_LOGO_KIT/Sign/svg/samolet_logo_sign_blue.svg";

const Disclaimer: FC = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 text-white w-full">
      <div className="flex flex-col gap-4  w-full items-center justify-center py-8 md:py-8">
        <h2 className="flex flex-col text-3xl justify-center font-[500] uppercase w-full px-8">
          Сервис Интеллектуального анализа речи "Харизма"
        </h2>
        <div className="flex flex-col justify-center items-start w-full">
          <div className="flex flex-row w-full justify-between gap-8 pl-8">
            <div className="flex flex-col justify-between">
              <p className="flex flex-col text-xl  justify-center font-[500 !important]  ">
                Расскажите, что вы любите делать больше всего?
              </p>
              <p className="flex flex-col text-xl  justify-center font-[500 !important]  ">
                Что вас радует? Хобби, работа, наука, семья?
              </p>

              <p className="flex flex-col text-md justify-center font-[500 !important] mt-8">
                по готовности нажмите кнопку и запишите аудио (от 30 до 60 сек)
              </p>
            </div>
            {/* <div className="flex flex-1 justify-end items-end  bg-white rounded-tl-full rounded-bl-full min-h-[4rem] max-h-[4rem] min-w-[4rem] max-w-[4rem]">
              <img className="" src={smlt_arrow} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
