export const uploadAudio = async (audio: Blob, services?: string[]) => {
  const formData = new FormData();

  formData.append("file", audio);

  const servicesString =
    services && Array.isArray(services) && services.length !== 0
      ? services.join(",")
      : "";
  formData.append("services", servicesString);

  const response = await fetch(
    `${import.meta.env.VITE_AUDIO_STORAGE_URL}/rest`,
    {
      method: "POST",
      headers: {
        "X-API-TOKEN": import.meta.env.VITE_AUDIO_STORAGE_UUID,
      },
      body: formData,
    }
  );

  const result = await response.json();

  return result;
};

export const getStatus = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_AUDIO_STORAGE_URL}/status`,
    {
      method: "GET",
    }
  );

  const result = await response.json();
  return result;
};

export const getResultsById = async (result_id: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_AUDIO_STORAGE_URL}/by_id?request_id=${result_id}`,
    {
      method: "GET",
      headers: {
        "X-API-TOKEN": import.meta.env.VITE_AUDIO_STORAGE_UUID,
      },
    }
  );

  const result = await response.json();
  return result;
};
