import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Metrics from "../components/metrics/Metrics";
import { getResultsById } from "../api/record.api";
import Spinner from "../components/ui/spinner";

const Results: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) return navigate("/");

    if (id && !state?.data) {
      setLoading(true);
      getResultsById(id)
        .then((result: any) => {
          navigate(".", { replace: true, state: { data: result } });
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <div className="flex h-full justify-center items-center w-full">
          <Spinner />
        </div>
      ) : (
        <section className="flex flex-col justify-center items-center gap-12 w-full h-full p-4 md:p-8 md:pt-0 lg:p-16">
          <h1 className="font-semibold  text-4xl">Ваши результаты</h1>
          <Metrics data={state?.data || []} />

          <Link to={"/"}>
            <button className="flex flex-col justify-center items-center h-[5vh] text-2xl font-[500] bg-sm-b text-white py-8 px-8">
              Записать ещё
            </button>
          </Link>
        </section>
      )}
    </>
  );
};

export default Results;
