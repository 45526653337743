import React from "react";
import MetricsHeader from "../../../features/MetricsHeader";
import MetricGroup from "../MetricGroup";
import { IMetricGroup } from "../../../entities/metrics/type";

interface SpeechMetricsProps {
  purity: IMetricGroup | undefined;
  coherence: IMetricGroup | undefined;
  simplicity: IMetricGroup | undefined;
  emotions: IMetricGroup | undefined;
  toxicity: IMetricGroup | undefined;
}

export default function SpeechMetrics({
  purity,
  coherence,
  simplicity,
  emotions,
  toxicity,
}: SpeechMetricsProps) {
  const metrics = [
    { group: purity, title: "Чистота речи", length: 3 },
    { group: coherence, title: "Связность речи", length: 3 },
    { group: simplicity, title: "Простота речи", length: 4 },
    { group: emotions, title: "Эмоциональность", length: 4 },
    { group: toxicity, title: "Токсичность", length: 5 },
  ];

  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-col gap-8 w-full">
        <MetricsHeader title="Показатели речи" />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-2 gap-5">
          {metrics.map((item, index) => (
            <MetricGroup
              key={`${item.title}_${index}`}
              group={item.group}
              title={item.title}
              length={item.length}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
