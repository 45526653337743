import Disclaimer from "./Disclaimer";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mic, XCircle, Upload, Loader } from "lucide-react";
import logo from "../../public/datasea_logo.jpg";
import { cn } from "../../lib/utils";
import RecordTimer from "./RecordTimer";
import { uploadAudio } from "../api/record.api";

export const Recorder = () => {
  const minRecordingLength = 30;
  // const maxRecordingLength = 1;

  const navigate = useNavigate();

  const [permission, setPermission] = useState<boolean | string>("default");

  const [uploadLoading, setUploadLoading] = useState(false);

  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const [recordingStatus, setRecordingStatus] = useState<string>("inactive");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [audioChunks, setAudioChunks] = useState<BlobPart[]>([]);

  const [stream, setStream] = useState<MediaStream | null>(null);

  const [recordedTime, setRecordedTime] = useState<number>(0);

  const [microphonePermission, setMicrophonePermission] = useState<string>("");

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone",
        } as unknown as PermissionDescriptor);

        if (permissionStatus.state === "granted") {
          getMicrophonePermission();
        }

        setMicrophonePermission(permissionStatus.state);
      } catch (error) {
        console.error(
          "Ошибка при запросе разрешения на использование микрофона:",
          error
        );
      }
    };

    checkMicrophonePermission();
  }, []);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        setStream(mediaStream);

        setPermission("yes");
      } catch (err) {
        setPermission("not");
      }
    } else {
      setErrorMessage("Браузер не поддерживает аудио запись");
    }
  };

  const startRecording = (stream: MediaStream | null) => {
    setRecordingStatus("recording");
    setErrorMessage("");

    if (stream) {
      const media: MediaRecorder = new MediaRecorder(stream);

      mediaRecorder.current = media;

      if (mediaRecorder.current instanceof MediaRecorder) {
        mediaRecorder.current.start();

        const localAudioChunks: BlobPart[] = [];

        mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
          if (typeof event.data === "undefined") return;
          if (event.data.size === 0) return;
          localAudioChunks.push(event.data);
        };

        setAudioChunks(localAudioChunks);
      }
    }
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");

    setUploadLoading(true);

    if (mediaRecorder.current instanceof MediaRecorder) {
      if (typeof mediaRecorder.current.stop === "function") {
        mediaRecorder.current.stop();

        mediaRecorder.current.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });

          uploadAudioHandler(audioBlob);

          setRecordedTime(0);
        };
      }
    }
  };

  const cancelRecording = () => {
    setRecordingStatus("inactive");

    if (mediaRecorder.current instanceof MediaRecorder) {
      if (typeof mediaRecorder.current.stop === "function") {
        mediaRecorder.current.stop();

        mediaRecorder.current.onstop = () => {
          setRecordedTime(0);
          setAudioChunks([]);
        };
      }
    }
  };

  const onSendHandler = () => {
    stopRecording();
  };

  const buttonText =
    recordingStatus === "inactive" && permission === "yes" ? (
      <>
        <Mic size={24} />
        Начать запись
      </>
    ) : permission === "not" || microphonePermission === "denied" ? (
      <>
        <Mic size={24} />
        Доступ закрыт
      </>
    ) : (
      <>
        <Mic size={24} />
        Включить микрофон
      </>
    );

  const currentButtonFunction = async () => {
    if (uploadLoading) return;

    if (permission === "default" || permission === "not") {
      await getMicrophonePermission();
    } else {
      startRecording(stream);
    }
  };

  const handleFileSelect = (event: any) => {
    const selectedFile = event.target.files[0];

    setErrorMessage("");

    if (selectedFile) {
      setUploadLoading(true);

      const audioBlob = new Blob([selectedFile], { type: "audio/mp3" });

      uploadAudioHandler(audioBlob);

      setRecordedTime(0);
    }
  };

  const uploadAudioHandler = (currentBlob: Blob) => {
    uploadAudio(currentBlob)
      .then((response: any) => {
        const resultId = response?.metadata?.id;

        if (response?.error) {
          setErrorMessage(
            "К сожалению сервер вернул ошибку. Попробуйте сделать запись снова"
          );
        } else {
          if (resultId) {
            navigate(`/results/${resultId}`, { state: { data: response } }); // Результат сохранятся в стор роутера
          }
        }
      })
      .catch(() => {
        setErrorMessage(
          "К сожалению сервер вернул ошибку. Попробуйте сделать запись снова"
        );
      })
      .finally(() => {
        setUploadLoading(false);
        if (formRef?.current && typeof formRef.current.reset === "function") {
          formRef.current.reset();
        }
      });
  };

  return (
    <>
      <img className="max-h-[16rem]" src={logo} />
      <section
        className={`flex flex-col justify-start items-center bg-slate-600 md:rounded-3xl recorder min-w-[50%]`}
      >
        <>
          <Disclaimer />

          <div className="flex flex-col gap-8 justify-end items-center w-full pb-5 ">
            {recordingStatus === "recording" && (
              <div className="flex flex-row gap-4 w-full px-8">
                <div className="flex flex-row gap-2 justify-center h-full min-w-[40%] items-center w-full ">
                  <p className="flex justify-center text-4xl font-bold text-white uppercase w-full">
                    <RecordTimer
                      recordingStatus={recordingStatus}
                      recordedTime={recordedTime}
                      setRecordedTime={setRecordedTime}
                    />
                  </p>
                </div>
                <button
                  onClick={cancelRecording}
                  className="flex flex-row flex-1 justify-center py-4 px-8 md:px-16 md:py-6 items-center text-2xl font-[500] gap-2 bg-[#faad14] text-white "
                >
                  <XCircle size={24} />
                  <span className="whitespace-nowrap">Отменить запись</span>
                </button>
              </div>
            )}
            <div className="flex w-full justify-center px-8">
              {recordingStatus === "recording" ? (
                <div
                  className={cn(
                    `flex flex-col w-full gap-4 end`,
                    recordingStatus === "recording" &&
                      recordedTime < minRecordingLength
                      ? "cursor-not-allowed pointer-events-none"
                      : ""
                  )}
                >
                  <button
                    onClick={onSendHandler}
                    className={cn(
                      `flex justify-center items-center h-full w-full px-16 py-6 text-2xl gap-2 bg-white font-[500] text-slate-600`,
                      recordingStatus === "recording" &&
                        recordedTime < minRecordingLength
                        ? "opacity-[20%] pointer-events-none"
                        : ""
                    )}
                  >
                    <Upload size={24} />
                    Завершить запись и отправить на обработку
                  </button>
                </div>
              ) : (
                <div className="flex flex-col w-full gap-1">
                  <div className="flex flex-row w-full gap-10">
                    <button
                      className={cn(
                        `flex flex-1 items-center justify-center h-full bg-white text-slate-600 font-[500] text-2xl gap-4 px-16 py-6`,
                        (permission === "not" && !uploadLoading) ||
                          (microphonePermission === "denied" && !uploadLoading)
                          ? "opacity-[20%]"
                          : ""
                      )}
                      onClick={currentButtonFunction}
                    >
                      {uploadLoading ? (
                        <>
                          <Loader size={24} />
                          Запись анализируется
                        </>
                      ) : (
                        buttonText
                      )}
                    </button>

                    {!uploadLoading && (
                      <label
                        htmlFor="fileInput"
                        className={cn(
                          `flex items-center justify-center h-full bg-white text-slate-600 font-[500] text-2xl gap-4 px-16 py-6`,
                          "send-file-button"
                        )}
                      >
                        <>
                          <Upload size={24} />
                          Отправить файл
                        </>
                      </label>
                    )}
                  </div>

                  {errorMessage ? (
                    <span className="text-center text-white pt-3">
                      {errorMessage}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </>
      </section>
      <form ref={formRef} style={{ display: "none" }}>
        <input
          type="file"
          id="fileInput"
          accept="audio/*"
          onChange={handleFileSelect}
        />
      </form>
    </>
  );
};
