import { useEffect } from "react";

export default function RecordTimer({
  recordingStatus,
  setRecordedTime,
  recordedTime,
}: {
  recordingStatus: string;
  setRecordedTime: (state: any) => void;
  recordedTime: number;
}) {
  useEffect(() => {
    let timer: any;

    if (recordingStatus === "recording") {
      timer = setInterval(() => {
        setRecordedTime((prevTime: any) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [recordingStatus]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return <span>{formatTime(recordedTime)}</span>;
}
