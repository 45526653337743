import { Progress } from "antd";
import { TypeMetric } from "../../entities/metrics/type";

export default function MetricsItemBar({
  item,
  index,
}: {
  item: TypeMetric;
  index: number;
}) {
  return (
    <>
      <li
        className="flex flex-col lg:flex-col  w-full font-semibold text-lg lg:items-left progress-wrap"
        key={index}
      >
        <p className="flex">{item?.name}:</p>
        <Progress
          className="lg:flex-row items-center text-xl gap-4"
          format={(percent?: number) => percent && `${Math.round(percent)} %`}
          percent={item?.value >= 1 ? item?.value : 0}
          success={{
            strokeColor: "#007BFC",
          }}
          size={["100%", 12]}
          status={
            item?.value < 50 ? "normal" : item?.isBad ? "exception" : "success"
          }
        />
      </li>
    </>
  );
}
