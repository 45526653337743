import { IMetricGroup } from "../../entities/metrics/type";
import CountBarsMetrics from "../../widget/CountBarsMetrics/CountBarsMetrics";
import MetricsItemBar from "./MetricsItemBar";

type MetricGroupComponent = {
  group?: IMetricGroup;
  title: string;
  length: number;
};

export default function MetricGroup({ title, group }: MetricGroupComponent) {
  return (
    <>
      {group && (
        <div className="flex flex-col px-10 py-5 gap-8 rounded-2xl border border-gray-200 w-full min-w-[300px] min-h-[400px]">
          <h3 className="font-semibold text-xl text-center ">
            {title !== "Показатели" && title}
          </h3>
          <ul className="flex flex-col w-full">
            <section className="flex flex-col w-full gap-3">
              {group?.value?.map((item, index) => {
                return group.type === "count" ? (
                  <CountBarsMetrics key={`${item.name}_${index}`} item={item} />
                ) : (
                  <MetricsItemBar
                    key={`${item.name}_${index}`}
                    item={item}
                    index={index}
                  />
                );
              })}
            </section>
          </ul>
        </div>
      )}
    </>
  );
}
