import { IMetricGroup, MetricsResponse } from "../../entities/metrics/type";
import { maxMetricsValues } from "../../entities/metrics/utils";
import MetricGroup from "./MetricGroup";
import WordsUsed from "./words-used/WordsUsed";
import SpeechMetrics from "./speech-metrics/SpeechMetrics";
import NeighboursGroup from "./neigbours-group/NeigboursGroup";

export default function Metrics({ data }: { data: MetricsResponse }) {
  const non_native = data?.values["non-native"];

  const normalizedData: IMetricGroup[] = [
    {
      type: "count",
      value: [
        {
          name: "Вероятность пола",
          value:
            data?.values?.gender && data?.values?.gender === "male" ? 1 : 0,
          percent: data?.values?.gender_proba,
          isBad: false,
          type: "count",
        },

        {
          name: "Длительность",
          value: data?.values?.total_duration || 0,
          isBad: false,
          type: "count",
          max: 100,
        },
        {
          name: "Скорость речи",
          value: data?.values?.speech_speed
            ? parseFloat(data?.values?.speech_speed.toFixed(1)) * 100
            : 0,
          isBad: false,
          type: "count",
          max: maxMetricsValues.wpm,
        },
        {
          name: "Высота записи (голоса)",
          value: data?.values?.pitch || 0,
          isBad: false,
          type: "count",
          max: maxMetricsValues.pitch,
        },
        {
          name: "Громкость голоса",
          value: data?.values?.loudness || 0,
          isBad: false,
          type: "count",
          max: maxMetricsValues.loudness,
        },
      ],
    },
    {
      type: "purity",
      value: [
        {
          name: "Доля слов, не несущих смысла (водность)",
          value: data?.values?.water || 0,
          isBad: true,
          type: "purity",
        },
        {
          name: "Повторяемость слов (тошнотность)",
          value: data?.values?.nausea || 0,
          isBad: true,
          type: "purity",
        },
        {
          name: "Доля слов-паразитов",
          value: data?.values?.parazits?.value || 0,
          wordList: data?.values?.parazits?.words || [],
          isBad: true,
          type: "purity",
        },
      ],
    },

    {
      type: "emotions",
      value: [
        {
          name: "Эмоциональность",
          value: data?.values?.emotion_level || 0,
          isBad: false,
          type: "emotions",
        },
        {
          name: "Экспрессивность",
          value: data?.values?.expressiveness || 0,
          isBad: false,
          type: "toxicity",
        },
        {
          name: "Радость",
          value: data?.values?.happiness || 0,
          isBad: false,
          type: "emotions",
        },
        {
          name: "Грусть",
          value: data?.values?.saddness || 0,
          isBad: false,
          type: "emotions",
        },
        {
          name: "Напористость",
          value: data?.values?.anger || 0,
          isBad: false,
          type: "emotions",
        },
      ],
    },
    {
      type: "toxicity",
      value: [
        {
          name: "Угрозы",
          value: data?.values?.threat || 0,
          isBad: true,
          type: "toxicity",
        },
        {
          name: "Оскорбления",
          value: data?.values?.insult || 0,
          isBad: true,
          type: "toxicity",
        },
        {
          name: "Непристойности",
          value: data?.values?.obscenity || 0,
          isBad: true,
          type: "toxicity",
        },
      ],
    },
    {
      type: "simplicity",
      value: [
        {
          name: "Сложность речи",
          value: data?.values?.complexity || 0,
          isBad: true,
          type: "simplicity",
        },
        {
          name: "Доля сложных слов",
          value: data?.values?.complex?.value || 0,
          isBad: true,
          type: "simplicity",
        },
        {
          name: "Доля заимствованных слов",
          value: non_native && non_native.value ? non_native?.value : 0,
          isBad: true,
          type: "simplicity",
        },
      ],
    },
  ];

  const neighbours = data?.values?.neighbours
    ? [...data.values.neighbours]
    : [];

  const formattedData = normalizedData.reduce(
    (acc: { [key: string]: IMetricGroup }, item: IMetricGroup) => {
      if (!acc[item.type]) {
        acc[item.type] = item;
      }

      if (item.type === "purity") {
        const parasites = item.value?.find(
          (i) => i.name === "Доля слов-паразитов"
        );
        if (parasites) {
          acc.parasites = { type: "parasites", value: [parasites] };
        }
      }

      return acc;
    },
    {}
  );

  return (
    <section className="flex flex-col w-full h-full max-w-[1670px] justify-center items-center gap-y-12">
      <MetricGroup group={formattedData.count} title="Показатели" length={6} />
      <NeighboursGroup group={neighbours} />
      <SpeechMetrics
        purity={formattedData.purity}
        coherence={formattedData.coherence}
        simplicity={formattedData.simplicity}
        emotions={formattedData.emotions}
        toxicity={formattedData.toxicity}
      />
      <WordsUsed parasites={formattedData.parasites} />
    </section>
  );
}
